


































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { AllocationListFilter } from "@/store/models/dsad";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  DSADStore,
  MetaStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Demand Forecast Report",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  generating: boolean = false;
  detailVisible: boolean = false;
  allocation: any = {};

  allocationfilter = {
    fiscalyearid: null,
    croptypeid: null,
    cropvarietyid: null,
    seedtypeid: null
  } as AllocationListFilter;

  urlquery: string = "";

  get dataList() {
    return DSADStore.DemandForecastReport;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  downloadReport() {
    if (this.dataList.file !== undefined) {
      var link = document.createElement("a");
      link.setAttribute("href", this.dataList.file);
      link.setAttribute("target", "_blank");
      link.click();
    }
  }

  async downloadDemandReport() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let query: any = {};
      // let query: any = { page: 1 };
      // this.pageNum = 1;
      // this.generating = true;
      if (this.allocationfilter.fiscalyearid != null) {
        query["fiscalyearid"] = String(this.allocationfilter.fiscalyearid);
      }
      if (this.allocationfilter.croptypeid != null) {
        query["croptypeid"] = String(this.allocationfilter.croptypeid);
      }
      if (this.allocationfilter.cropvarietyid != null) {
        query["cropvarietyid"] = String(this.allocationfilter.cropvarietyid);
      }
      if (this.allocationfilter.seedtypeid != null) {
        query["seedtypeid"] = String(this.allocationfilter.seedtypeid);
      }
      this.routerReplacer();
      this.generating = true;
      await DSADStore.getDemandForecastReport(query);
      this.generating = false;
    }
  }

  routerReplacer(page?: number) {
    let query: any = {};
    if (page != null) {
      query["page"] = String(page);
    }
    if (this.allocationfilter.fiscalyearid != null) {
      query["fiscalyearid"] = String(this.allocationfilter.fiscalyearid);
    }
    if (this.allocationfilter.croptypeid != null) {
      query["croptypeid"] = String(this.allocationfilter.croptypeid);
    }
    if (this.allocationfilter.cropvarietyid != null) {
      query["cropvarietyid"] = String(this.allocationfilter.cropvarietyid);
    }
    if (this.allocationfilter.seedtypeid != null) {
      query["seedtypeid"] = String(this.allocationfilter.seedtypeid);
    }
    if (this.$route.name) {
      this.$router.replace({
        name: this.$route.name,
        query: query
      }).catch(error => {});
    }
    return query;
  }

  clearSearch() {
    this.pageNum = 1;
    this.allocationfilter.fiscalyearid = null;
    this.allocationfilter.croptypeid = null;
    this.allocationfilter.cropvarietyid = null;
    this.allocationfilter.seedtypeid = null;
    this.routerReplacer();
    // DSADStore.getAdminAllocation();
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.allocationfilter.croptypeid && this.allocationfilter.croptypeid !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.allocationfilter.croptypeid);
    }
    return varieties;
  }

  changeCrop() {
    this.allocationfilter.cropvarietyid = null;
  }

  private get fiscalyear() {
    let fiscalyr = MetaStore.FiscalYear;
    return fiscalyr;
  }

  get provinces() {
    return MetaStore.Province;
  }

  getProvinceNameValue() {
    let provinceList = [];
    for (let i of this.provinces) {
      provinceList.push({ text: i.name, value: i.name });
    }
    return provinceList;
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer(this.pageNum);
    await DSADStore.getDemandForecastReport(query);
    this.loading = false;
  }

  private async created() {
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.loading = true;
    MetaStore.getAllProvince();
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVarietiesList();
    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getAllSeedType();

    this.loading = false;
    if (this.$route.query.fiscalyearid) {
      this.allocationfilter.fiscalyearid = Number(this.$route.query.fiscalyearid);
    }

    if (this.$route.query.croptypeid) {
      this.allocationfilter.croptypeid = Number(this.$route.query.croptypeid);
    }

    if (this.$route.query.cropvarietyid) {
      this.allocationfilter.cropvarietyid = Number(this.$route.query.cropvarietyid);
    }

    if (this.$route.query.seedtypeid) {
      this.allocationfilter.seedtypeid = Number(this.$route.query.seedtypeid);
    }

    let query = this.routerReplacer();
    if (
      this.allocationfilter.fiscalyearid &&
      this.allocationfilter.croptypeid &&
      this.allocationfilter.seedtypeid
    ) {
      DSADStore.getDemandForecastReport(query);
    } else {
      DSADStore.clearDemandForecastReport();
    }
  }
}
