





























































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import {
  CropStore,
  MetaStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore
} from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import Marketstat from "@/components/UIComponents/Marketstat.vue";

@Component({
  metaInfo: {
    title: "Balance Sheet",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;
  generating: boolean = false;

  fiscalYearId: number | null = null;
  cropId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    } else {
      varieties = [];
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }
  private async created() {
    this.loading = true;
    await MetaStore.getAllDistrict();
    await MetaStore.getAllFiscalYear();
    await CropStore.getAllCropName();
    await CropVarietyStore.getAllCropVariety();
    await DemandSupplyStore.getAllSeedType();
    this.loading = false;
  }

  async download() {
    if (this.fiscalYearId != null && this.cropId != null && this.seedTypeId != null) {
      this.generating = true;
      let error: boolean = false;
      let report = await DemandSupplyStore.getBalanceSheetReport({
        fiscalYearId: this.fiscalYearId,
        cropTypeId: this.cropId,
        seedTypeId: this.seedTypeId
      }).catch(res => {
        error = true;
      });

      if (report !== undefined && error == false) {
        var link = document.createElement("a");
        link.setAttribute("href", report);
        link.click();
      } else {
        this.$notify.error("Error Generating report!!! Please try again");
      }
      this.generating = false;
    }
  }
}
