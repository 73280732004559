































































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Admin",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Admin Panel", vmid: "admin" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class Adminmenu extends Vue {}
